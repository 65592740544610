var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SearchStateController", {
        staticClass: "mt-4",
        attrs: { default: _vm.defaultFilters, s: _vm.s },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var state = ref.state
              return [
                _c("ScrimSearchFilters", {
                  attrs: { value: state },
                  on: { update: _vm.onSearch }
                }),
                _c("hr"),
                _c("ScrimTeamStats", { attrs: { query: state } })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }