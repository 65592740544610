<template>
  <div>
    <SearchStateController :default="defaultFilters" :s="s" class="mt-4">
      <template #default="{ state }">
        <ScrimSearchFilters :value="state" @update="onSearch" />
        <hr />
        <ScrimTeamStats :query="state" />
      </template>
    </SearchStateController>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import SearchStateController from '@/components/controllers/SearchStateController.vue'
import ScrimSearchFilters from '@/components/scrim/ScrimSearchFilters.vue'
import mixpanel from '@/mixpanel'
import { serializeQuery } from '@/utils/searchQuery'
import ScrimTeamStats from '@/views/scrims/ScrimTeamStats.vue'

export default {
  name: 'ScrimTeamStatsPage',
  components: {
    SearchStateController,
    ScrimTeamStats,
    ScrimSearchFilters,
  },
  data() {
    return {
      loading: 0,
      hasMapData: true,
      statsData: {},
    }
  },
  computed: {
    ...mapGetters({
      defaultFilters: 'search/scrimSearchFilters',
    }),

    s() {
      return this.$route.query.s
    },
  },
  methods: {
    ...mapMutations({
      resetFilters: 'search/RESET_FILTERS',
      saveFilters: 'search/SET_FILTERS',
    }),

    async onSearch(query) {
      this.loading++
      try {
        const serializedQuery = await serializeQuery(query)
        if (query == null) {
          this.resetFilters()
        } else {
          this.saveFilters(query)
        }
        if (this.s !== serializedQuery) {
          await this.$router.push({ query: { s: serializedQuery != null ? serializedQuery : undefined } })
        }
      } finally {
        this.loading--
      }

      mixpanel.track_search(query, query ? 'Search' : 'Reset')
    },
  },
}
</script>

<style lang="scss" scoped></style>
