<template>
  <div>
    <div v-if="loading" class="flex-fill d-flex justify-content-center mt-4 mb-4">Loading...</div>

    <div v-else>
      <div v-if="hasMapData">
        <CompositionsStats :stats-data="statsData" />

        <PerformanceStats :stats-data="statsData" />

        <!-- Temporary disabled until verify api data -->
        <!-- <ChartPlantPerformance v-if="!hideBrokenStuff" class="mb-5" :stats-data="statsData" /> -->

        <!-- TODO need api update from Mori -->
        <!-- <TableRoundPerformance
          class="mb-5"
          :data="null"
        /> -->

        <!-- <TableWeaponStats class="mb-5" :data="statsData.weaponsUsage" :show_pick_percentage="false" /> -->
      </div>

      <div v-else class="text-center text-muted mt-5 mb-5">No map data</div>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { findScrims, getScrim, getScrimAdvancedRoundInfo } from '@/api/scrims'
// import ChartPlantPerformance from '@/components/Chart/PlantPerformance.vue'
// import TableWeaponStats from '@/components/Table/WeaponStats.vue'
import CompositionsStats from '@/components/team/stats/CompositionsStats.vue'
import PerformanceStats from '@/components/team/stats/PerformanceStats.vue'
import { calcScrimTeamStats } from '@/utils/calcScrimTeamStats.js'
import { isClutch, isTradeKill } from '@/utils/killfeed.js'
import { prepareScrimData } from '@/utils/prepareScrimData'

export default {
  name: 'ScrimTeamStats',

  components: {
    //   TableWeaponStats,
    //   ChartPlantPerformance,
    CompositionsStats,
    PerformanceStats,
  },

  props: {
    query: px.object,
    teamData: px.object,
  },

  data: () => ({
    loading: false,
    hasMapData: true,
    statsData: {
      compositions: {},
    },
  }),

  computed: {
    ...mapGetters({
      agentsById: 'static/agentsById',
      getAgentById: 'static/getAgentById',
      getMapById: 'static/getMapById',
      weapons: 'static/weaponsById',
      // selected_map_id: 'team/selected_map_id',
      // teamId: 'team/id',
    }),
  },

  watch: {
    query: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v) {
          this.loadData(v)
        }
      },
    },
  },

  methods: {
    async loadData(query) {
      this.loading = true
      try {
        const { data: scrimSearchItems } = await findScrims(query)
        const scrims = []
        for (const scrimSearchItem of scrimSearchItems) {
          const scrim = await getScrim(scrimSearchItem.id)

          const rounds = []
          for (let index = 0; index < scrimSearchItem.rounds_played; index++) {
            rounds.push(index)
          }

          const roundData = await Promise.all(
            rounds.map(async round_num => {
              try {
                const data = await getScrimAdvancedRoundInfo(scrimSearchItem.id, round_num)
                return data
              } catch (e) {
                console.error(e)
              }
            })
          )

          scrims.push(prepareScrimData.call(this, scrim, roundData))
        }

        this.statsData = calcScrimTeamStats(
          this.agentsById,
          this.weapons,
          scrims,
          this.getMapById(this.selected_map_id)
        )
        console.log('raw stats', this.statsData)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    calcPlayerAgents(positions) {
      const playerAgents = []
      positions.forEach(matchPositions => {
        matchPositions.teams
          ?.find(team => team.id === this.teamId)
          ?.players?.forEach(player => {
            playerAgents.push({
              match_id: matchPositions.id,
              agent_name: this.getAgentById(player.agent_id).name,
              agent_display_icon_url: this.getAgentById(player.agent_id).display_icon_url,
              ...player,
            })
          })
      })
      return playerAgents
    },
    calcRoundPerformance(players, positions) {
      const roundPerformance = {
        firstBlood: {
          atk: {
            wins: 0,
            total: 0,
          },
          def: {
            wins: 0,
            total: 0,
          },
          ecoItems: {},
        },
        firstDeath: {
          atk: {
            wins: 0,
            total: 0,
          },
          def: {
            wins: 0,
            total: 0,
          },
          ecoItems: {},
        },
        clutch: {
          atk: {
            wins: 0,
            total: 0,
          },
          def: {
            wins: 0,
            total: 0,
          },
          ecoItems: {},
        },
        trade: {
          atk: {
            wins: 0,
            total: 0,
          },
          def: {
            wins: 0,
            total: 0,
          },
          ecoItems: {},
        },
        antiEco: {
          atk: {
            wins: 0,
            total: 0,
          },
          def: {
            wins: 0,
            total: 0,
          },
          ecoItems: {},
        },
        eco: {
          atk: {
            wins: 0,
            total: 0,
          },
          def: {
            wins: 0,
            total: 0,
          },
          ecoItems: {},
        },
        fullBuy: {
          atk: {
            wins: 0,
            total: 0,
          },
          def: {
            wins: 0,
            total: 0,
          },
          ecoItems: {},
        },
      }

      positions?.forEach(matchPositions => {
        matchPositions.rounds?.forEach(roundData => {
          const team = roundData?.teams?.find(team => team.id === this.teamId)
          const oppTeam = roundData?.teams?.find(team => team.id !== this.teamId)

          if (roundData.kills && roundData.kills.length > 0) {
            const firstKill = roundData.kills?.sort((a, b) => a.round_time_millis - b.round_time_millis)[0]

            if (players.some(player => player.puuid === firstKill.killer_puuid)) {
              // check if first blood was given by this team
              this.updateRoundPerformanceItem(roundPerformance.firstBlood, team)
            } else {
              this.updateRoundPerformanceItem(roundPerformance.firstDeath, team)
            }

            // Check for clutch
            if (isClutch(players, roundData.kills)) {
              this.updateRoundPerformanceItem(roundPerformance.clutch, team)
            }

            // Check for trade kill
            if (roundData.kills.some(kill => isTradeKill(kill, roundData.kills))) {
              this.updateRoundPerformanceItem(roundPerformance.trade, team)
            }
          }

          // Check for anti eco
          if (team.eco === '$$$$' && oppTeam.eco !== '$$$$') {
            this.updateRoundPerformanceItem(roundPerformance.antiEco, team)
          }

          // Check for eco
          if (team.eco !== '$$$$' && oppTeam.eco === '$$$$') {
            this.updateRoundPerformanceItem(roundPerformance.eco, team)
          }

          // Check for full buy
          if (team.eco === '$$$$' && oppTeam.eco === '$$$$') {
            this.updateRoundPerformanceItem(roundPerformance.fullBuy, team)
          }
        })
      })

      return [
        {
          scenario: 'First blood convertion',
          title: 'Percentage of won rounds when the team made the first blood',
          ...roundPerformance.firstBlood,
          ecoItems: Object.entries(roundPerformance.firstBlood.ecoItems).map(([eco, item]) => {
            return { eco: eco, ...item }
          }),
        },
        {
          scenario: 'First death convertion',
          title: 'Percentage of won rounds when the team gave the first death',
          ...roundPerformance.firstDeath,
          ecoItems: Object.entries(roundPerformance.firstDeath.ecoItems).map(([eco, item]) => {
            return { eco: eco, ...item }
          }),
        },
        {
          scenario: 'Clutch win rate',
          title: 'Percentage of won rounds when the team was in a clutch (1Vx)',
          ...roundPerformance.clutch,
          ecoItems: Object.entries(roundPerformance.clutch.ecoItems).map(([eco, item]) => {
            return { eco: eco, ...item }
          }),
        },
        {
          scenario: 'Trade rate',
          title: 'Percentage of won rounds when the team made a trade kill',
          ...roundPerformance.trade,
          ecoItems: Object.entries(roundPerformance.trade.ecoItems).map(([eco, item]) => {
            return { eco: eco, ...item }
          }),
        },
        {
          scenario: 'Anti eco convertion',
          title: 'Percentage of won rounds when the team is full-buy vs non full-buy',
          ...roundPerformance.antiEco,
          ecoItems: Object.entries(roundPerformance.antiEco.ecoItems).map(([eco, item]) => {
            return { eco: eco, ...item }
          }),
        },
        {
          scenario: 'Eco convertion',
          title: 'Percentage of won rounds when the team is not full-buy vs full-buy',
          ...roundPerformance.eco,
          ecoItems: Object.entries(roundPerformance.eco.ecoItems).map(([eco, item]) => {
            return { eco: eco, ...item }
          }),
        },
        {
          scenario: 'Full buy convertion',
          title: 'Percentage of won rounds when the team is full-buy vs full-buy',
          ...roundPerformance.fullBuy,
          ecoItems: Object.entries(roundPerformance.fullBuy.ecoItems).map(([eco, item]) => {
            return { eco: eco, ...item }
          }),
        },
      ]
    },
    updateRoundPerformanceItem(item, team) {
      item.ecoItems[team.eco] = item.ecoItems[team.eco] || {
        atk: {
          wins: 0,
          total: 0,
        },
        def: {
          wins: 0,
          total: 0,
        },
      }

      if (team.role?.toLowerCase() === 'atk') {
        item.atk.total += 1
        item.atk.wins += team.win ? 1 : 0
        item.ecoItems[team.eco].atk.total += 1
        item.ecoItems[team.eco].atk.wins += team.win ? 1 : 0
      } else {
        item.def.total += 1
        item.def.wins += team.win ? 1 : 0
        item.ecoItems[team.eco].def.total += 1
        item.ecoItems[team.eco].def.wins += team.win ? 1 : 0
      }
    },
    calcGeneralStats(matches, positions) {
      const stats = {
        wins: 0,
        losses: 0,
        rounds_atk_won: 0,
        rounds_atk_lost: 0,
        rounds_atk_won_perc: 0,
        rounds_def_won: 0,
        rounds_def_lost: 0,
        rounds_def_won_perc: 0,
      }
      matches.forEach(match => {
        const teamResult = match?.summary?.results?.find(team => team.id === this.teamId)
        stats.wins += teamResult?.won ? 1 : 0
        stats.losses += teamResult?.won ? 0 : 1
      })

      positions.forEach(matchPositions => {
        matchPositions.rounds?.forEach(roundData => {
          const team = roundData?.teams?.find(team => team.id === this.teamId)

          if (team.role?.toLowerCase() === 'atk') {
            stats.rounds_atk_won += team.win ? 1 : 0
            stats.rounds_atk_lost += team.win ? 0 : 1
          } else {
            stats.rounds_def_won += team.win ? 1 : 0
            stats.rounds_def_lost += team.win ? 0 : 1
          }
        })
      })

      return stats
    },
  },
}
</script>

<style></style>
