var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "flex-fill d-flex justify-content-center mt-4 mb-4" },
          [_vm._v("Loading...")]
        )
      : _c("div", [
          _vm.hasMapData
            ? _c(
                "div",
                [
                  _c("CompositionsStats", {
                    attrs: { "stats-data": _vm.statsData }
                  }),
                  _c("PerformanceStats", {
                    attrs: { "stats-data": _vm.statsData }
                  })
                ],
                1
              )
            : _c("div", { staticClass: "text-center text-muted mt-5 mb-5" }, [
                _vm._v("No map data")
              ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }